import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "in-app-frame" }
const _hoisted_2 = { class: "frame-title" }
const _hoisted_3 = { class: "frame-main" }
const _hoisted_4 = ["src"]

import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePopupStore } from '@/store'


export default {
  __name: 'InAppFrame',
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const popupStore = usePopupStore();
const { frameUrl, frameTitle } = storeToRefs(popupStore);

const isHideHeader = ref(false)

onMounted(() => {
  setTimeout(() => {
    isHideHeader.value = true 
  }, 1000)
})

return (_ctx, _cache) => {
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: true,
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["frame-header", { 'hide': isHideHeader.value }])
        }, [
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "close-btn flex justify-center",
            onClick: _cache[0] || (_cache[0] = $event => (emit('onClose')))
          }, [
            _createVNode(_component_van_icon, {
              name: "close",
              class: "close-icon"
            })
          ])), [
            [_directive_audio, void 0, "close"]
          ]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(frameTitle)), 1),
          _createElementVNode("div", {
            class: "hide-wrap",
            onClick: _cache[1] || (_cache[1] = $event => (isHideHeader.value = !isHideHeader.value))
          }, [
            (isHideHeader.value)
              ? (_openBlock(), _createBlock(_component_van_icon, {
                  key: 0,
                  name: "arrow-down",
                  class: "arrow-icon"
                }))
              : (_openBlock(), _createBlock(_component_van_icon, {
                  key: 1,
                  name: "arrow-up",
                  class: "arrow-icon"
                }))
          ])
        ], 2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("iframe", {
            src: _unref(frameUrl),
            width: "100%",
            height: "100%",
            style: {"border":"none"}
          }, null, 8, _hoisted_4)
        ])
      ]),
      _: 1
    })
  ]))
}
}

}