import { defineStore } from 'pinia'

export const usePopupStore = defineStore('popup', {
  state: () => {
    return {
      rewards: [],
      frameUrl: '',
      frameTitle: '',
      frameCloseFn: undefined,
      activeInformation: '',
      showReward: false,
      showFollow: false,
      showInAppFrame: false,
      showAgeVerifiction: false,
      showInformation: false,
    }
  },
  actions: {
    set(key, value = true) {
      this[key] = value;
    },
    showRewards(rewards) {
      if(Array.isArray(rewards)) {
        this.rewards = rewards;
      } else {
        this.rewards = [{ type: "cash", amount: rewards }];
      }
      this.showReward = true;
    },
    openUrlInFrame(url, title = '', closeCallback) {
      this.frameUrl = url;
      this.frameTitle = title;
      this.frameCloseFn = closeCallback || undefined;
      this.showInAppFrame = true;
    },
    closeUrlInFrame() {
      this.frameUrl = '';
      this.frameTitle = '';
      if (this.frameCloseFn) {
        this.frameCloseFn()
      }
      this.frameCloseFn = undefined;
      this.showInAppFrame = false;
    },
    openInformation(active) {
      this.activeInformation = active || 0;
      this.showInformation = true;
    }
  }
})
