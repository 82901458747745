import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "information-popup" }
const _hoisted_2 = { class: "popup-main" }
const _hoisted_3 = { class: "popup-header" }
const _hoisted_4 = { class: "popup-title" }
const _hoisted_5 = { class: "popup-content" }

import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePopupStore } from '@/store'
import About from '@/doc/About';
import KYCVerification from '@/doc/KYCVerification';
import PrivacyPolicy from '@/doc/PrivacyPolicy';
import TermsAndCondition from '@/doc/TermsAndCondition';
import Responsibility from '@/doc/Responsibility';


export default {
  __name: 'Information',
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const popupStore = usePopupStore();
const { activeInformation } = storeToRefs(popupStore);

const active = ref(activeInformation || 0);

return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_tab = _resolveComponent("van-tab")
  const _component_van_tabs = _resolveComponent("van-tabs")
  const _component_van_popup = _resolveComponent("van-popup")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: true,
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('information.title').toUpperCase()), 1),
            _createVNode(_component_van_image, {
              class: "close-icon",
              src: require('@/assets/img/common/close.png'),
              onClick: _cache[0] || (_cache[0] = $event => (emit('onClose')))
            }, null, 8, ["src"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_van_tabs, {
              active: active.value,
              "onUpdate:active": _cache[1] || (_cache[1] = $event => ((active).value = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_tab, { title: "GBET" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(About))
                  ]),
                  _: 1
                }),
                _createVNode(_component_van_tab, {
                  title: _ctx.$t('ageVerification.termsAndCondition').toUpperCase()
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(TermsAndCondition))
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_van_tab, {
                  title: _ctx.$t('ageVerification.privacyPolicy').toUpperCase()
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(PrivacyPolicy))
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_van_tab, {
                  title: _ctx.$t('ageVerification.kycVerification').toUpperCase()
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(KYCVerification))
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_van_tab, {
                  title: _ctx.$t('ageVerification.responsibility').toUpperCase()
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Responsibility))
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_van_tab, { title: "" })
              ]),
              _: 1
            }, 8, ["active"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
}

}