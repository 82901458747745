import { showDialog, showConfirmDialog, closeDialog } from 'vant';
import 'vant/es/dialog/style';
import i18n from '@/locales/index';

const confirm = param => {
  const { message, type, showClose, ...rest } = param;
  let closeBtnHtml = ''
  if (showClose) {
    closeBtnHtml = `<img class="close-dialog-icon" src="/images/notice/close.png" />`
    setTimeout(() => {
      document.querySelector('.my-dialog .close-dialog-icon').addEventListener('click', (e) => {
        closeDialog();
      })
    }, 500)
  }
  return showConfirmDialog({
    className: `my-dialog`,
    message: // `<div class="my-dialog-header">${i18n.global.t('msg.notice')}</div>` +
             closeBtnHtml +
             `<img class="my-dialog-icon" src="/images/notice/${ type || 'warn'}.png" />` +
             `<div><p class="my-dialog-text">${message}</p></div>`,
    allowHtml: true,
    theme: 'round-button',
    confirmButtonText: i18n.global.t('common.confirm').toUpperCase(),
    cancelButtonText: i18n.global.t('common.cancel').toUpperCase(),
    ...rest,
    title: undefined,
  });
};

const alert = param => {
  const { message, type, showClose, ...rest } = param;
  let closeBtnHtml = ''
  if (showClose) {
    closeBtnHtml = `<img class="close-dialog-icon" src="/images/notice/close.png" />`
    setTimeout(() => {
      document.querySelector('.my-dialog .close-dialog-icon').addEventListener('click', (e) => {
        closeDialog();
      })
    }, 500)
  }
  return showDialog({
    className: 'my-dialog',
    message: // `<div class="my-dialog-header">${i18n.global.t('msg.notice')}</div>` +
             closeBtnHtml +
             `<img class="my-dialog-icon" src="/images/notice/${ type || 'warn'}.png" />` +
             `<div><p class="my-dialog-text">${message}</p></div>`,
    allowHtml: true,
    theme: 'round-button',
    confirmButtonText: i18n.global.t('common.close').toUpperCase(),
    ...rest,
    title: undefined,
  });
};

export default { confirm, alert };