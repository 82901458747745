import { defineStore } from 'pinia'
import { storage } from '@/utils/storage'
import i18n from '@/locales/index';
import { usePlatform } from "@/hooks";
import MyDialog from '@/plugins/component/MyDialog';
import { queryConfig, queryAppVersion } from '@/api';

const Platform = usePlatform();

export const useSettingsStore = defineStore('settings', {
  state: () => {
    return {
      theme: storage.get('theme', 'light'), //主题色
      isSound: storage.get('isSound', false), // 提示音
      isMusic: storage.get('isMusic', false), // 背景音乐
      facebookUrl: '',
      telegramUrl: '',
      onlineService: '',
      downloadPage: '',
      deviceId: '',
      version: '',
      versionCode: '',
      showDownloadBanner: (!window.$bridge && !Platform.isIOS) ? true : false, //是否显示首页下载广告
      aboutUs: '',
      responsibleGaming: '',
      termsAndConditions: '',
      privacyPolicy: '',
      helpCenter: '',
    }
  },
  actions: {
    fetchConfig() {
      queryConfig().then(res => {
        let config = res.data || {}
        this.facebookUrl = config['cs_facebook'];
        this.telegramUrl = config['cs_telegram'];
        this.onlineService = config['cs_online'];
        this.downloadPage = config['download_page'];
        this.aboutUs = config['about_us'];
        this.responsibleGaming = config['responsible_gaming'];
        this.termsAndConditions = config['terms_of_use'];
        this.privacyPolicy = config['privacy_policy'];
        this.helpCenter = config['help_center'];
      })
    },
    initDeviceInfo() {
      if (window.$bridge) {
        window.$bridge.callHandler('getDeviceInfo', '', result => {
          let data = JSON.parse(result);
          this.deviceId = data.deviceId;
          this.version = data.versionName;
          this.versionCode = data.versionCode;
        });
      }
    },
    checkAppVersion() {
      if (window.$bridge) {
        queryAppVersion().then(res => {
          let { versionCode, downloadUrl } = res.data || {}
          if (!this.versionCode || this.versionCode < versionCode) {
            MyDialog.confirm({
              message: i18n.global.t('msg.versionUpdate'),
            })
            .then(() => {// on confirm
              window.$bridge.callHandler('openBrowser', downloadUrl)
            })
            .catch(() => {});
          }
        })
      }
    },
    setSound(value) {
      this.isSound = value
      storage.set('isSound', value, null)
    },
    setMusic(value) {
      this.isMusic = value
      storage.set('isMusic', value, null)
    },
    setTheme(value) {
      document.body.classList.remove(`theme-${this.theme}`);
      document.body.classList.add(`theme-${value}`);
      this.theme = value;
      storage.set('theme', value, null)
    },
    hideDownloadBanner() {
      this.showDownloadBanner = false;
    }
  }
})
