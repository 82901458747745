import { router } from '@/router';
import i18n from '@/locales/index';
import MyDialog from '@/plugins/component/MyDialog';
import useClipboard from 'vue-clipboard3';
import { useUserStore, useSettingsStore } from '@/store';
import { shareSocialMedia, followSocialMedia, enterGame } from "@/api";

export const useHelpTool = () => {
  const showLoginDialog = () => {
    MyDialog.confirm({
      message: i18n.global.t('notify.loginFirst'),
      type: 'error',
    })
    .then(() => {// on confirm
      router.push({ name: 'login' })
    })
    .catch(() => {});
  }

  const showKycSuspendDialog = (options = {}) => {
    MyDialog.confirm({
      message: i18n.global.t('notify.kycSuspend'),
      type: 'error',
      confirmButtonText: 'Customer Service',
      cancelButtonText: 'Complete KYC',
      showClose: !options.noClose
    })
    .then(() => {// on confirm
      router.push({ name: 'livechat' })
    })
    .catch(() => {
      router.push({ name: 'kycVerification' })
    });
  }

  const showKycWarnDialog = () => {
    MyDialog.alert({
      message: i18n.global.t('notify.kycWarn'),
      confirmButtonText: 'Verify Now',
      showClose: true
    })
    .then(() => {// on close
      router.push({ name: 'kycVerification' })
    });
  }

  const checkUserLogin = (callback) => {
    if (!useUserStore().uid) {
      showLoginDialog();
    } else {
      callback && callback();
    }
  }

  const doShare = (postData) => {
    checkUserLogin(()=> {
      shareSocialMedia(postData).then(async res => {
        let url = res.data;
        if (window.$bridge) {
          window.$bridge.callHandler('share', JSON.stringify({
            method: 'app',
            url,
            type: 'url'
          }))
        } else {
          try {
            await useClipboard().toClipboard(url);
            MyDialog.alert({ message: i18n.global.t('notify.shareSuccess') });
          } catch (e) {
            console.error(e)
          }
        }
      })
    })
  }

  const doFollow = (postData) => {
    followSocialMedia(postData).then(res => {
      let url = res.data;
      openBrowser(url, { showAlert: true });
    })
  }

  const doEnterGame = (gid) => {
    checkUserLogin(()=> {
      enterGame({
        gid,
      }).then(res => {
        let { playUrl, openMode } = res.data || {};
        if (openMode === 'browser') {
          openBrowser(playUrl, { showAlert: true })
        } else {
          openUrl(playUrl, { showAlert: true });
        }
      });
    })
  }

  const openUrl = (url, options) => {
    if (window.$bridge) {
      window.$bridge.callHandler('openUrl', url);
    } else {
      openUrlInWeb(url, options);
    }
  }

  const openBrowser = (url, options) => {
    if (window.$bridge) {
      window.$bridge.callHandler('openBrowser', url);
    } else {
      openUrlInWeb(url, options);
    }
  }

  const openUrlInWeb = (url, options = {}) => {
    if (options.showAlert || options.message) {
      MyDialog.alert({
        type: 'success',
        message: options.message ? options.message : i18n.global.t('msg.openBrowser'),
        confirmButtonText: i18n.global.t('common.go').toUpperCase(),
      })
      .then(() => {// on close
        let a = document.createElement('a');
        a.setAttribute('target','_blank');
        a.setAttribute('href', url);
        document.body.appendChild(a);
        a.click();
        a.remove();

        options.callback && options.callback();
      });
    } else {
      let a = document.createElement('a');
      a.setAttribute('target','_blank');
      a.setAttribute('href', url);
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  const imageByTheme = (image) => {
    return require(`@/assets/img/theme/${useSettingsStore().theme}/${image}`);
  }

  const backgroundByTheme = (image) => {
    return 'background-image: url(' + imageByTheme(image) + ');';
  }

  return { 
    showLoginDialog,
    showKycSuspendDialog,
    showKycWarnDialog,
    checkUserLogin, 
    doShare, 
    doFollow, 
    doEnterGame, 
    openUrl, 
    openBrowser, 
    imageByTheme, 
    backgroundByTheme
  }
}
