import { toDisplayString as _toDisplayString, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68b73af0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "terms-check-wrap" }

import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { usePopupStore, useSettingsStore } from '@/store'



export default {
  __name: 'TermsCheck',
  props: ['modelValue', 'color', 'highlight'],
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const popupStore = usePopupStore();
const settingsStore = useSettingsStore();
const { termsAndConditions, privacyPolicy } = storeToRefs(settingsStore);


const checked = ref(props.modelValue || false);

watch(checked, (newValue, oldValue) => {
  emit('update:modelValue', newValue)
})

return (_ctx, _cache) => {
  const _component_van_checkbox = _resolveComponent("van-checkbox")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_van_checkbox, {
      class: "terms-wrap",
      modelValue: checked.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((checked).value = $event)),
      shape: "square"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "terms-label",
          style: _normalizeStyle({ color: __props.color })
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('ageVerification.content')) + " ", 1),
          _createElementVNode("span", {
            class: "highlight",
            style: _normalizeStyle({ color: __props.highlight || __props.color }),
            onClick: _cache[0] || (_cache[0] = $event => (_unref(popupStore).openInformation(1)))
          }, _toDisplayString(_ctx.$t('ageVerification.termsAndCondition')), 5),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('common.and')) + " ", 1),
          _createElementVNode("span", {
            class: "highlight",
            style: _normalizeStyle({ color: __props.highlight || __props.color }),
            onClick: _cache[1] || (_cache[1] = $event => (_unref(popupStore).openInformation(2)))
          }, _toDisplayString(_ctx.$t('ageVerification.privacyPolicy')), 5)
        ], 4)
      ]),
      _: 1
    }, 8, ["modelValue"])), [
      [_directive_audio]
    ])
  ]))
}
}

}