export const useImageTool = () => {
  // 图片压缩  默认最大 500KB
  const compressImage = (file, maxSize = 500 * 1024) => {
    if(file.size <= maxSize) {
      return file;
    } else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const width = img.width;
            const height = img.height;
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            let quality = 0.8;
            let newBase64Image, resultBlob;
            do {
              newBase64Image = canvas.toDataURL('image/jpeg', quality);
              resultBlob = base64ToBlob(newBase64Image);
              quality = Number(quality).sub(0.1);
            } while (resultBlob.size > maxSize && quality > 0.1);
            resolve(new File([resultBlob], file.name, { type: file.type }));
          };
        };
        reader.onerror = error => reject(error);
      });
    }
  }

  const base64ToBlob = base64 => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  return {
    compressImage,
  }
}
