import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d540b59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "back-btn flex justify-center" }

import { useRoute, useRouter } from 'vue-router'


export default {
  __name: 'NavBar',
  props: ['title'],
  setup(__props) {



const route = useRoute()
const router = useRouter()


const goBack = () => {
  if(route.name === 'login' || route.name === 'register') {
    router.replace({ name: 'game' })
  // } else if (route.name === 'forgetPassword') {
  } else {
    router.back()
  } 
}


return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createBlock(_component_van_nav_bar, {
    title: __props.title,
    border: false,
    onClickLeft: goBack
  }, {
    left: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_van_image, {
          class: "back-icon",
          src: require('@/assets/img/common/back.png')
        }, null, 8, ["src"])
      ])), [
        [_directive_audio, void 0, "close"]
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

}